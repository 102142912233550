import React from 'react'
import PropTypes from 'prop-types'

import { isBrowser } from '../utils/isBrowser'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    if (isBrowser) {
      this.setState({ error })

      window.Sentry.configureScope((scope) => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key])
        })
      })

      window.Sentry.captureException(error)
    }
  }

  render() {
    if (this.state.error) {
      return <h1>Something went wrong!</h1>
    } else {
      return this.props.children
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

