import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import { FormattedNumber, injectIntl, FormattedMessage } from 'react-intl'
import Truncate from 'react-truncate'
import styled from 'styled-components'
import { Box, Flex, Text } from 'rebass/styled-components'

import { OutlineLink } from '../button'
import VideoLabel from './video-label'
import {
  Title,
  Price,
  ProductId,
} from './styles'

const ImageWrapper = styled(Box)`
  position: relative;

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    max-height: 187px;
    overflow: hidden;
  }
`

const ImageLink = styled(Link)`
  img {
    transform-origin: center center;
    transform: scale3d(1, 1, 1);
    transition: all 250ms ${props => props.theme.easing} !important;
  }

  &:hover {
    img {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const ProductCard = ({ slug, sku, title, excerpt, image, currency, price, video, intl }) => {
  const [showLink] = useState(false)
  const [showExcerpt] = useState(true)
  const { locale: currentLocale } = intl

  return (
    <Flex width="100%" flexDirection="column" justifyContent="flex-start">
      <ImageWrapper mb={3}>
        <ImageLink to={slug}>
          <Img fluid={image.childImageSharp.fluid} alt={excerpt} />
        </ImageLink>
      </ImageWrapper>

      <StyledLink to={slug}>
        <Title as="h2" title={title}>
          <Truncate lines={1} width={300}>{title}</Truncate>
        </Title>

        {showExcerpt && (
          <Text mb={3} color="mutedText">
            <Truncate lines={2}>{excerpt}</Truncate>
          </Text>
        )}

        <Flex justifyContent="space-between" alignItems="center">
          <Price>
            <FormattedNumber
              style='currency'
              value={price}
              currency={currency}
              minimumFractionDigits={currentLocale === 'cs' ? 0 : 2}
              maximumFractionDigits={currentLocale === 'cs' ? 0 : 2}
            />
          </Price>

          <Flex justifyContent="flex-end" alignItems="center">
            {video && <VideoLabel />}
            {sku && <ProductId>ID: {sku}</ProductId>}
          </Flex>
        </Flex>
      </StyledLink>

      {showLink && (
        <OutlineLink isBlock to={slug}>
          <FormattedMessage id="product.show" defaultMessage="Show product" />
        </OutlineLink>
      )}
    </Flex>
  )
}

ProductCard.propTypes = {
  slug: PropTypes.string,
  sku: PropTypes.string,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  image: PropTypes.object,
  currency: PropTypes.string,
  price: PropTypes.number,
  video: PropTypes.string,
  intl: PropTypes.object,
}

ProductCard.defaultProps = {
  video: null,
}

export default injectIntl(ProductCard)
