import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import styled, { css } from 'styled-components'
import { Box, Flex } from 'rebass/styled-components'
import { CSSTransition } from 'react-transition-group'

import Container from './container'
import Logo from './logo'
import MenuToggle from './menu-toggle'
// import LanguageSwitcher from './language-switcher'
import Nav from './nav'
import media from '../styles/media'

const headerHeight = css`
  height: calc(
    ${props => props.theme.header.height + 'rem' + ' + ' + (2 * (props.theme.header.verticalPadding) + 'rem')}
  );
`

const StyledHeader = styled(Box)`
  ${headerHeight};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow-y: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  transition:
    height 200ms ${props => props.theme.easing},
    background-color 200ms ${props => props.theme.easing};
  z-index: 200;

  &.header-enter {
    
  }

  &.header-enter-active {
    height: 100%;
  }

  &.header-enter-done {
    height: 100%;
    z-index: 3000;
    background-color: rgba(255, 255, 255, 1);

    & li {
      transform: translateY(0);
      opacity: 1;
    }
  }

  ${media.greaterThan('mobile')`
    ${headerHeight};
  `}
`

const HeaderPlaceholder = styled.div`
  ${headerHeight};
`

const Header = ({ siteTitle, menu, homeLink }) => { // isHome, langs,
  const [isMenuOpen, toggleMenuOpen] = useState(false)

  return (
    <>
      <CSSTransition in={isMenuOpen} timeout={0} classNames="header">
        <StyledHeader as="header">
          <Container px={[0, 3, 3]} height="100%">
            <Flex
              flexDirection={['row', 'row']}
              justifyContent={['center', 'flex-start']}
              alignItems={['flex-start', 'center']}
              flexWrap={['wrap', 'no-wrap']}
              height="100%"
            >
              <Box flexBasis={['20%', 'auto']} order={[0, 1]} justifySelf={['center', 'auto']} ml={[0, 4]}>
                {/* <LanguageSwitcher langs={langs} /> */}
              </Box>

              <Box order={[1, 0]} mx={['auto', '0']}>
                <Link to={homeLink || '/'} style={{ display: 'block', lineHeight: 0 }}>
                  <Logo alt={siteTitle} />
                </Link>
              </Box>

              <Flex flexBasis={['20%', 'auto']} order={3} justifyContent="flex-end">
                <MenuToggle type="button" onClick={() => toggleMenuOpen(!isMenuOpen)} isMenuOpen={isMenuOpen} aria-label={isMenuOpen ? 'Hide menu' : 'Show menu'} />
              </Flex>

              <Box flexBasis={['100%', 'auto']} order={4} ml={[0, 'auto']}>
                <Nav menu={menu} isMenuOpen={isMenuOpen} />
              </Box>
            </Flex>
          </Container>
        </StyledHeader>
      </CSSTransition>

      <HeaderPlaceholder />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  langs: PropTypes.array,
  isHome: PropTypes.bool,
  menu: PropTypes.array,
  homeLink: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
