import React from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import styled, { css } from 'styled-components'
import { Box } from 'rebass/styled-components'

import heroLogo from '../images/hero-logo.svg'
import { Heading } from 'rebass/styled-components'

const headerHeight = '5rem'

const heroMinHeight = (fullHeight) => css`
  ${fullHeight ? `calc(80vh - ${headerHeight})` : `calc(50vh - ${headerHeight})`};
`

const HeroWrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${props => heroMinHeight(props.fullHeight)};
  z-index: 1;
`

const HeroImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : 'none'};
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
`

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-color: ${props => props.theme.colors.text};
`

const HeroBody = styled(Box)`
  position: relative;
  max-width: ${props => props.maxWidth ? props.maxWidth : 'none'};
  padding: 4rem;
`

const HeroTitle = props =>
  <Heading
    {...props}
    sx={{
      color: 'white',
      fontSize: 8,
      textShadow: 'text',
    }}
  />

const HeroLogo = styled.img`
  max-width: 100%;
  color: #FFFFFF;
  fill: currentColor;
  stroke: currentColor;
`

const Hero = ({ fluid, alt, title, maxWidth = null, fullHeight = false, showLogo = false }) => {
  return (
    <HeroWrapper fullHeight={fullHeight}>
      <HeroImage maxWidth={maxWidth}>
        <StyledBackgroundImage fluid={fluid} alt={alt} />
      </HeroImage>
      <HeroBody mr={['0', '0', '45%']} maxWidth={maxWidth}>
        {title && <HeroTitle as="h1">{title}</HeroTitle>}
        {!title && showLogo && <HeroLogo src={heroLogo} alt="Sharp Blades" />}
      </HeroBody>
    </HeroWrapper>
  )
}

Hero.propTypes = {
  fluid: PropTypes.object.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  maxWidth: PropTypes.string,
  fullHeight: PropTypes.bool,
  showLogo: PropTypes.bool,
}

Hero.defaultProps = {
  alt: '',
  title: null,
  subtitle: null,
  maxWidth: null,
  fullHeight: false,
  showLogo: false,
}

export default Hero
