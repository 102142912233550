import styled from 'styled-components'

export default styled.ul`
  line-height: 1.75;

  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    li a {
      display: flex;
      align-items: center;
      padding: 0.25rem 0;
    }
  }
`
