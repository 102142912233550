import { createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }

  body {
    color: ${props => props.theme.colors.text};
    font-family: ${props => props.theme.fonts.custom};
  }

  a {
    color: ${props => props.theme.colors.text};
  }

  ol, ul, p {
    margin: 0 0 1rem;
    line-height: 1.75;
  }

  ol, ul {
    padding: 0;
    list-style: none;
  }

  iframe {
    border: none;
  }

  hr {
    border: 0;
    border-top: 1px solid ${props => rgba(props.theme.colors.text, 0.1)};
  }

  select,
  textarea,
  input {
    outline: none;
  }

  textarea,
  input[type=text],
  input[type=password],
  input[type=search],
  input[type=email],
  input[type=tel] {
    -webkit-appearance: none;
    appearance: none;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`

export default GlobalStyle
