module.exports = {
  'page-not-found': 'Page not found',
  homepage: {
    bestSellers: 'Best Sellers',
    newArrivals: 'New arrivals',
  },
  contact: {
    title: 'Contact',
  },
  carousel: {
    back: 'Previous',
    next: 'Next',
  },
  forms: {
    optional: 'Optional',
  },
  footer: {
    followUs: 'Follow us',
    copyright: 'Copyright {siteName} © {year}. All right reserved.',
  },
  payment: {
    progress: 'Payment in progress...',
    successful: 'Payment successful. E-mail with confirmation will arrive soon!',
    cancelled: 'Payment cancelled. Please try again.',
    failed: 'Payment failed. Please try again.',
    total: 'Total',
    shipping: 'Shipping',
  },
  product: {
    backToCollection: 'Back to collection',
    show: 'Show product',
    buy: 'Buy',
    accessories: 'Accessories',
    priceWithoutTax: 'Price without tax',
    tax: 'Tax',
    noTax: 'No tax',
    taxWithRate: 'Tax ({taxRate} %) VAT',
    order: {
      personalInformation: 'Personal information',
      shippingAddress: 'Shipping address',
      buttonLabel: 'Order',
      placeOrderButtonLabel: 'Place order',
      placingOrder: 'Placing order...',
      cancelOrder: 'Cancel order',
      failed: 'Order failed. Please try again.',
      success: 'Order successfully sent.',
    },
    quantity: 'Quantity',
    share: 'Share',
    galleryContainsProductVideo: 'Gallery contains product video.',
    paypal: {
      loading: 'Loading payment gateway...',
    },
    properties: {
      length: 'Length',
      overallLength: 'Overall length',
      bodyLength: 'Body length',
      headWidth: 'Head width',
      headLength: 'Head length',
      axeHeadLength: 'Axe head length',
      chainLength: 'Chain length',
      handleLength: 'Handle length',
      bladeLength: 'Blade length',
      bladeWidth: 'Blade width',
      size: 'Size',
      surfaceTreatment: 'Surface treatment',
      thickness: 'Thickness',
      weight: 'Weight',
      material: 'Material',
      handle: 'Handle',
      piecesInSet: 'Pieces in set',
    },
  },
  order: {
    firstname: {
      label: 'First name',
      placeholder: 'Your first name',
      required: 'First name is required.',
      min: 'First name is too short.',
      max: 'First name is too long.',
    },
    lastname: {
      label: 'Last name',
      placeholder: 'Your last name',
      required: 'Last name is required.',
      min: 'Last name is too short.',
      max: 'Last name is too long.',
    },
    email: {
      label: 'E-mail',
      labelDescriptions: 'We will use this e-mail address to contact you.',
      placeholder: 'email@example.com',
      required: 'E-mail is required.',
      invalid: 'E-mail is invalid.',
    },
    message: {
      label: 'Message',
      labelDescription: 'Tell us more about customization you want etc.',
      placeholder: 'Specify your requirements...',
      min: 'Message has to be longer than 2 characters.',
      max: 'Message cannot be longer than 2000 characters.',
    },
    addressLine1: {
      label: 'Address line 1',
      placeholder: 'Street address etc.',
      required: 'Address line 1 is required.',
      min: 'Address line 1 is too short.',
      max: 'Address line 1 is too long.',
    },
    addressLine2: {
      label: 'Address line 2',
      placeholder: 'Apt. number, house etc.',
      min: 'Address line 2 is too short.',
      max: 'Address line 2 is too long.',
    },
    city: {
      label: 'City',
      placeholder: 'e.g. Miami',
      min: 'City is too short.',
      max: 'City is too long.',
    },
    state: {
      label: 'State',
      placeholder: 'e.g. FL',
      min: 'State is too short.',
      max: 'State is too long.',
    },
    postalCode: {
      label: 'Postal code',
      placeholder: 'e.g. 33192',
      min: 'Postal code is too short.',
      max: 'Postal code is too long.',
    },
    country: {
      label: 'Country',
      placeholder: 'Country',
      prompt: 'Select country...',
      min: 'Country is too short.',
      max: 'Country is too long.',
    },
    acceptTerms: {
      label: 'I accept&nbsp;<a>Terms & conditions</a>',
      required: 'You have to accept terms and conditions.',
    },
  },
}
