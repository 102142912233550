import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import StyledFooterList from './footer-list'
import StyledRightUpIcon from './right-up-icon'

const SocialLinks = () => {
  const data = useStaticQuery(graphql`
    query SocialLinksQuery {
      site {
        siteMetadata {
          social {
            type
            label
            value
          }
        }
      }
    }
  `)
  const socialLinks = data.site.siteMetadata.social

  if (socialLinks.length > 0) {
    return (
      <StyledFooterList>
        {socialLinks.map(link => {
          return (
            <li key={link.value}>
              <a href={link.value} target="_blank" rel="noopener noreferrer">
                {link.label} <StyledRightUpIcon />
              </a>
            </li>
          )
        })}
      </StyledFooterList>
    )
  }

  return null
}

export default SocialLinks
