import styled from 'styled-components'
import media from '../styles/media'

const lineWidth = 40 // percent
const easing = 'cubic-bezier(0.86, 0, 0.07, 1)'

const MenuToggle = styled.button`
  position: relative;
  width: 5rem;
  height: 5rem;
  padding: 1rem 2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 3000;

  &:before,
  &:after {
    content: '';
    width: ${lineWidth}%;
    height: 2px;
    left: ${(100 - lineWidth) / 2}%;
    right: ${(100 - lineWidth) / 2}%;
    position: absolute;
    background-color: ${props => props.theme.colors.text};
    transform-origin: center center;
    transition: ${props => {
    if (props.isMenuOpen) {
      return `top 100ms ${easing}, bottom 100ms ${easing}, transform 100ms ${easing} 100ms`
    } else {
      return `top 100ms ${easing} 100ms, bottom 100ms ${easing} 100ms, transform 100ms ${easing}`
    }
  }};
    will-change: transform, top, bottom;
  }

  &:before {
    top: ${props => props.isMenuOpen ? '50%' : '45%'};
    ${props => props.isMenuOpen ? 'transform: rotate(45deg)' : null};
  }

  &:after {
    top: ${props => props.isMenuOpen ? '50%' : '55%'};
    ${props => props.isMenuOpen ? 'transform: rotate(-45deg)' : null};
  }
  
  ${media.greaterThan('mobile')`
    display: none;
  `}
`

export default MenuToggle
