import React from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { Box } from 'rebass/styled-components'

import VideoIcon from '../../images/video.inline.svg'
import { PropTypes } from 'prop-types'

const StyledVideoIcon = styled(VideoIcon)`
  width: 14px;
  height: 9px;
  margin-right: 0.25rem;
`

const VideoLabel = ({ intl }) => {
  return (
    <Box title={intl.formatMessage({ id: 'product.galleryContainsProductVideo', defaultMessage: 'Gallery contains product video.' })} sx={{
      fontSize: '0.8rem',
      mr: 2,
      opacity: 0.9,
    }}>
      <StyledVideoIcon style={{ color: '#a0a0a0', fill: 'currentColor' }} />
    </Box>
  )
}

VideoLabel.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(VideoLabel)
