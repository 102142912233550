import Link from 'gatsby-link'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'

const outlineButtonStyle = css`
  display: ${props => props.isBlock ? 'flex' : 'inline-flex'};
  align-items: center;
  justify-content: center;
  width: ${props => props.isBlock ? '100%' : 'auto'};
  padding: 0.75rem 1rem;
  background-color: transparent;
  border: 1px solid ${props => props.theme.colors ? props.theme.colors.text : '#333'};
  color: ${props => props.theme.colors ? props.theme.colors.text : '#333'};
  cursor: pointer;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition:
    background-color 200ms ease-in-out, color 200ms ease-in-out, opacity 200ms ease-in-out;

  &[disabled] {
    opacity: 0.9;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors ? props.theme.colors.text : '#333'};
    color: ${props => props.theme.colors ? props.theme.colors.background : '#fff'};
  }
`

export const Button = styled.button`
  ${outlineButtonStyle}
`

export const OutlineLink = styled(Link)`
  ${outlineButtonStyle}
`

export const WhiteButton = styled(Button)`
  background-color: #FFFFFF;
`

export const NoBorderButton = styled(Button)`
  border: none;
`

export const UnstyledButton = styled.button`
  padding: 0;
  margin: 0;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: 0;
`

export const BuyButton = styled.button`
  position: relative;
  width: 100%;
  height: 45px;
  min-height: 30px;
  max-height: 55px;
  background-color: ${props => props.theme.colors.paypalBlack};
  border: none;
  /* border-radius: 4px; */
  color: #fff;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  vertical-align: top;
  transition: background-color 200ms ease-in-out;

  &:focus,
  &:hover {
    /* background-color: ${props => lighten(0.1, props.theme.colors.paypalBlack)}; */
  }

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

export const ExternalLink = styled.a`
  position: relative;
  width: 100%;
  height: 45px;
  min-height: 30px;
  max-height: 55px;
  background-color: ${props => props.theme.colors.paypalBlack};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  vertical-align: top;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 200ms ease-in-out;

  &:focus,
  &:hover {
    /* background-color: ${props => lighten(0.1, props.theme.colors.paypalBlack)}; */
  }

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }
`
