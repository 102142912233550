import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../layouts'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Section from '../components/section'
import Container from '../components/container'

const AboutPage = props => {
  const { data, children, _frontmatter } = props
  const { title } = _frontmatter

  return (
    <Layout {...props} locale="en">
      <SEO title={title} />
      <Hero
        fluid={data.file.childImageSharp.fluid}
        alt={title}
        title={title}
        maxWidth="1440px"
      />

      <Section>
        <Container maxWidth='780px'>
          {children}
        </Container>
      </Section>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  children: PropTypes.array,
  _frontmatter: PropTypes.object,
}

export default AboutPage
