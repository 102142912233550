import styled from 'styled-components'

import RightUpIcon from '../images/up-right-icon.inline.svg'

const StyledRightUpIcon = styled(RightUpIcon)`
  margin-left: 0.5rem;
  width: 19px;
  height: 19px;

  polyline,
  line {
    color: inherit;
    stroke: currentColor;
  }
`

export default StyledRightUpIcon
