import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import HeaderLogo from '../images/header-logo.inline.svg'

const StyledHeaderLogo = styled(HeaderLogo)`
  width: auto;
  max-height: 5rem;
  padding: 1rem 0;
  color: ${props => props.theme.colors.text};
  fill: currentColor;
  stroke: currentColor;
`

const Logo = ({ alt }) => <StyledHeaderLogo title={alt} />

Logo.propTypes = {
  alt: PropTypes.string,
}

export default Logo
