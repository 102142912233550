import { lighten } from 'polished'

const darkGray = '#2C2E2F'
const beige = '#FCFBF6'
const green = '#0C7C59'
const orange = '#D8973C'
const red = '#A5243D'
const paypalBlack = '#2C2E2F'
const easing = 'cubic-bezier(0.32, 0.08, 0.24, 1)'

export const theme = {
  breakpoints: ['46em', '60em', '78em'],
  colors: {
    primary: darkGray,
    text: darkGray,
    background: beige,
    footerBackground: darkGray,
    inputBorderColor: lighten(0.4, darkGray),
    accessoryBorder: lighten(0.6, darkGray),
    mutedText: lighten(0.2, darkGray),
    success: green,
    warning: orange,
    error: red,
    paypalBlack,
    white: '#FFF',
  },
  fonts: {
    sans: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Oxygen, Ubuntu, Cantarell, \'Open Sans\', \'Helvetica Neue\', sans-serif',
    custom: 'Chivo, sans-serif',
    heading: 'Chivo, sans-serif',
  },
  lineHeights: {
    heading: '1.25',
    text: '1.75',
  },
  fontWeights: {
    heading: 'bold',
  },
  fontSizes: [12, 14, 16, 18, 21, 24, 28, 32, 48, 64, 72],
  textShadow: {
    text: '0 3px 12px rgba(0, 0, 0, 0.2)',
  },
  boxShadows: {
    box: '0 3px 12px rgba(0, 0, 0, 0.2)',
  },
  header: {
    height: 3,
    verticalPadding: 1,
  },
  text: {
    accessoriesTitle: {
      mb: 3,
      fontSize: 3,
      fontWeight: 'normal',
    },
    accessoryTitle: {
      mb: 1,
      fontSize: 2,
      fontWeight: 'normal',
    },
    accessoryDescription: {
      fontSize: 1,
    },
  },
  variants: {
    heroTitle: {
      color: 'white',
      textShadow: 1,
    },
  },
  easing,
}
