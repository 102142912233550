import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../layouts'
import SEO from '../components/seo'
import Section from '../components/section'
import Container from '../components/container'

const TermAndConditionsPage = (props) => {
  return (
    <Layout {...props} locale="en">
      <SEO title="Terms & conditions" />

      <Section>
        <Container maxWidth='780px'>
          {props.children}
        </Container>
      </Section>
    </Layout>
  )
}

TermAndConditionsPage.propTypes = {
  children: PropTypes.node,
}

export default TermAndConditionsPage
