import React from 'react'
import { PropTypes } from 'prop-types'
import Link from 'gatsby-link'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'

import media from '../styles/media'

const NavItemEasing = 'cubic-bezier(0.32, 0.08, 0.24, 1)'

const NavItems = styled.ul`
  margin-bottom: 0;
  list-style: none;
  display: flex;

  ${media.lessThan('mobile')`
    margin: 0;
    padding: 1rem 0;
    flex-basis: 100%;
    flex-direction: column;
    flex-grow: 1;
    visibility: ${props => props.isMenuOpen ? 'visible' : 'hidden'};
    transition: visibility 0s linear ${props => props.isMenuOpen ? '100ms' : '0s'};
  `}

  ${media.greaterThan('mobile')`
    position: static;
    padding: 0;
    flex-basis: auto;
    flex-direction: row;
    flex-grow: 0;
  `}
`

const NavItem = styled.li`
  opacity: 0;
  transform: translateY(-4px);
  transition: transform 200ms ${NavItemEasing}, opacity 200ms ${NavItemEasing};

  ${() => {
    let stack
    for (let i = 0; i < 20; i++) {
      stack += `&:nth-child(${i}) {
        transition-delay: ${(i+1) * 20 + 200}ms, ${(i+1) * 20 + 200}ms;
      }`
    }
    return stack
  }}

  ${media.greaterThan('mobile')`
    opacity: 1;
    transform: translateY(0);
  `}
`

const NavLink = styled(Link)`
  display: block;
  padding: 0.5rem 0;
  color: ${props => props.theme.colors.text};
  font-size: 1.25rem;
  text-decoration: none;
  text-align: center;
  
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  ${media.greaterThan('mobile')`
    display: inline-block;
    padding: 0.5rem 1rem;
    text-align: left;
  `}

  &.active,
  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.text};
    color: #FFFFFF;
  }
`

const Nav = ({ menu, isMenuOpen, intl }) => {
  const { locale: currentLocale } = intl

  return (
    <NavItems isMenuOpen={isMenuOpen}>
      {menu.map(item => {
        // Uncomment to enable multilanguage website (nned to be preapred for that)
        // const slug = `/${currentLocale}${item.slug}`
        const slug = `${item.slug}`

        if (item.language === currentLocale) {
          return (
            <NavItem key={slug}>
              <NavLink to={slug} activeClassName="active">{item.label}</NavLink>
            </NavItem>
          )
        }
      })}
    </NavItems>
  )
}

Nav.propTypes = {
  menu: PropTypes.array.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
}

Nav.defaultProps = {
  isMenuOpen: false,
}

export default injectIntl(Nav)
