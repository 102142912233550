import React from 'react'
import PropTypes from 'prop-types'
import { Box } from 'rebass/styled-components'

const Section = ({ py, children }) => (
  <Box py={py || [4, 5]}>
    {children}
  </Box>
)

Section.propTypes = {
  py: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  children: PropTypes.node.isRequired,
}

export default Section
