import styled from 'styled-components'
import { Box } from 'rebass/styled-components'
import { lighten } from 'polished'

import media from '../../styles/media'

export const Wrapper = styled.div`
  ${media.greaterThan('mobile')`
    display: grid;
    grid-template-columns: 60% auto;
  `}
  ${media.greaterThan('tablet')`
    grid-template-columns: 700px auto;
  `}
`

export const Content = styled.section`
  grid-column: 1;
  margin-bottom: 1rem;

  ${media.greaterThan('mobile')`
    margin-bottom: 0;
    margin-right: 2rem;
  `}

  ${media.greaterThan('tablet')`
    margin-right: 4rem;
  `}
`

export const Aside = styled.aside`
  grid-column: 2;
`

export const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  font-weight: normal;

  a {
    text-decoration: none;
  }
`

export const Price = styled.p`
  margin-bottom: 0;
  font-size: 1.15rem;
  font-weight: 700;
`

export const ProductId = styled.p`
  margin: 0 0.5rem 0 0;
  color: ${props => lighten(0.1, props.theme.colors.text)};
  font-size: 0.85em;
`

export const TaxInfo = styled.p`
  margin: 0;
  color: ${props => lighten(0.1, props.theme.colors.text)};
  font-size: 0.85em;
`

export const Description = styled(Box)`
  font-size: 0.95rem;
`
