import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledMain = styled.main`
  background-color: ${props => props.theme.colors.background};
`

const Main = props => {
  return (
    <StyledMain>{props.children}</StyledMain>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
