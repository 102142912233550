import React from 'react'
import { Box } from 'rebass/styled-components'
import { PropTypes } from 'prop-types'

const Container = (props) => {
  const px = props.px || 3

  return (
    <Box
      sx={{
        maxWidth: props.maxWidth || 1140,
        mx: 'auto',
        px: px,
        height: props.height || 'auto',
      }}
    >
      {props.children}
    </Box>
  )
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  px: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
  ]),
  maxWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
}

export default Container
