import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Link from 'gatsby-link'

import StyledFooterList from './footer-list'
import { PropTypes } from 'prop-types'
import { injectIntl } from 'react-intl'

const FooterLinks = ({ intl }) => {
  const { locale: currentLocale } = intl
  const data = useStaticQuery(graphql`
    query FooterLinksQuery {
      site {
        siteMetadata {
          footerLinks {
            label
            slug
            language
          }
        }
      }
    }
  `)
  const footerLinks = data.site.siteMetadata.footerLinks

  if (footerLinks.length > 0) {
    return (
      <StyledFooterList>
        {footerLinks.map(link => {
          if (link.language === currentLocale) {
            // Uncomment to enable multilanguage website (nned to be preapred for that)
            // const slug = `/${currentLocale}${item.slug}`
            const slug = `${link.slug}`

            return (
              <li key={slug}>
                <Link to={slug}>
                  {link.label}
                </Link>
              </li>
            )
          }
        })}
      </StyledFooterList>
    )
  }
}

FooterLinks.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(FooterLinks)
