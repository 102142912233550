module.exports = {
  'page-not-found': 'Stránka nenalezena',
  'carousel-back': 'Předchozí',
  'carousel-next': 'Následující',
  'footer-follow-us': 'Sledujte nás',
  'footer-copyright': 'Copyright {siteName} © {year}. Všechna práva vyhrazena.',
  'payment-progress': 'Platba probíhá...',
  'payment-successful': 'Platba proběhla úspěšně. Za chvíli obdržíte e-mail s potvrzením!',
  'payment-cancelled': 'Platba zrušena. Zkuste to prosím znovu.',
  'payment-failed': 'Platba selhala. Zkuste to prosím znovu.',
  'payment-total': 'Celkem',
  'payment-shipping': 'Doprava',
  'product-show': 'Zobrazit produkt',
  'product-buy': 'Koupit',
  'product-order': 'Objednat',
  'product-order-now': 'Objecnat ihned',
  'product-paypal-loading': 'Načítám platební bránu...',
  'product-quantity': 'Množství',
  'product-share': 'Sdílet',
  'length': 'Délka',
  'overallLength': 'Celková délka',
  'bodyLength': 'Délka těla',
  'headWidth': 'Délka hlavy',
  'handleLength': 'Délka rukojeti',
  'bladeLength': 'Délka čepele',
  'bladeWidth': 'Šířka čepele',
  'thickness': 'Tloušťka',
  'weight': 'Váha',
  'material': 'Materiál',
  'handle': 'Rukojeť',
  'piecesInSet': 'Počet kusů v balení',
  'contact-title': 'Kontakt',
}
