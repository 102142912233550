/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { IntlProvider } from 'react-intl'
import { getLangs, getUrlForLang, getCurrentLangKey, isHomePage } from 'ptz-i18n'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'

import GlobalStyle from '../styles/globalStyle'
import { theme } from '../styles/theme'
import ErrorBoundary from '../components/error-boundary'
import Header from '../components/header'
import Main from '../components/main'
import Footer from '../components/footer'

const siteMetadataQuery = graphql`
  query siteMetadataQuery {
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
        menu {
          label
          slug
          language
        }
      }
    }
  }
`

const Layout = (props) => {
  const { children, location } = props
  const url = location.pathname
  const isHome = isHomePage(url)

  const data = useStaticQuery(siteMetadataQuery)

  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const { menu } = data.site.siteMetadata
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)

  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={langKey} messages={props.i18nMessages}>
          <>
            <Normalize />
            <GlobalStyle />

            <Header
              menu={menu}
              isHome={isHome}
              // homeLink={homeLink}
              siteTitle={data.site.siteMetadata.title}
              langs={langsMenu}
            />

            <Main>
              {children}
            </Main>

            <Footer siteTitle={data.site.siteMetadata.title} />
          </>
        </IntlProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string,
  location: PropTypes.object.isRequired,
  data: PropTypes.object,
  i18nMessages: PropTypes.object.isRequired,
}

export default Layout
