/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'

import Layout from './_layout'
import { en, cs } from '../data/messages'
import { flattenMessages } from '../utils/flattenMessages'

const locales = {
  'cs': flattenMessages(cs),
  'en': flattenMessages(en),
}

export default (props) =>
  <Layout
    {...props}
    i18nMessages={locales[props.locale]}
  />
