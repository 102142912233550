/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const getProductUrl = (url, product) => `${url}/product/${product.frontmatter.slug}/`

const getSchemaOrgJSONLD = ({
  isProduct,
  url,
  title,
  product,
  image,
  description,
}) => {
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url,
      name: title,
    },
  ]

  return isProduct
    ? [
      ...schemaOrgJSONLD,
      {
        '@context': 'http://schema.org',
        '@type': 'Product',
        url: getProductUrl(url, product),
        name: title,
        image: [`${url}${image}`],
        description,
        sku: product.frontmatter.sku,
        brand: {
          '@type': 'Thing',
          name: 'Sharp Blades',
        },
        offers: {
          '@type': 'Offer',
          url: getProductUrl(url, product),
          priceCurrency: product.frontmatter.currency.toUpperCase(),
          price: product.frontmatter.price,
          priceValidUntil: new Date(),
          availability: 'https://schema.org/PreOrder',
        },
      },
    ]
    : schemaOrgJSONLD
}

const SEO = ({
  description,
  lang,
  // meta,
  title,
  isProduct = false,
  product = null,
  image = null,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              email
            }
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const { siteUrl } = site.siteMetadata
  const meta = []

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    isProduct,
    url: site.siteMetadata.siteUrl,
    product,
    title: isProduct ? title : `${title} × ${site.siteMetadata.title}`,
    image,
    description,
  })

  if (image) {
    meta.push({
      name: 'twitter:image',
      content: `${siteUrl}${image}`,
    })
    meta.push({
      property: 'og:image',
      content: `${siteUrl}${image}`,
    })
    meta.push({
      property: 'og:url',
      content: getProductUrl(siteUrl, product),
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s × ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.name,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {/* Schema.org tags */}
      <script type='application/ld+json'>
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  isProduct: false,
  image: null,
  product: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  isProduct: PropTypes.bool,
  image: PropTypes.string,
  product: PropTypes.object,
}

export default SEO
