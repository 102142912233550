import { graphql } from 'gatsby';
import { Box, Flex } from 'rebass/styled-components';
import IndexPage from "../../../../src/pages/index";
import { OutlineLink } from "../../../../src/components/button";
import WarrantyIcon from "../../../../src/images/warranty.inline.svg";
import FreeShippingIcon from "../../../../src/images/free-shipping.inline.svg";
import CoinIcon from "../../../../src/images/coin.inline.svg";
import adam from "../../../../src/images/adam-celadin.jpg";
import * as React from 'react';
export default {
  graphql,
  Box,
  Flex,
  IndexPage,
  OutlineLink,
  WarrantyIcon,
  FreeShippingIcon,
  CoinIcon,
  adam,
  React
};