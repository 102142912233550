import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Box, Flex } from 'rebass/styled-components'

import Container from './container'
import SocialLinks from './social-links'
import media from '../styles/media'
import FooterLinks from './footer-links'
import heroLogo from '../images/hero-logo.svg'

const StyledFooter = styled.footer`
  padding: 2rem 0;
  background-color: ${props => props.theme.colors.footerBackground};
  color: #FFFFFF;

  a {
    color: #FFFFFF;
    text-decoration: none;
  }
`

const FooterColumns = styled.div`
  ${media.greaterThan('mobile')`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}
`

const FooterTitle = styled.p`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`

// eslint-disable-next-line react/prop-types
const Column = ({ children }) => <Box mb={4}>{children}</Box>

const Copyright = styled.p`
  margin-bottom: 0;
  font-size: 0.8rem;
`

const FooterLogo = styled.img`
  width: 64px;
  height: auto;
  opacity: 0.5;
`

const Footer = ({ siteTitle }) => (
  <StyledFooter>
    <Container maxWidth="780px">
      <FooterColumns>
        <Column>
          <FooterTitle>{siteTitle}</FooterTitle>

          <FooterLinks />
        </Column>

        <Column>
          <FooterTitle>
            <FormattedMessage id="footer.followUs" defaultMessage="Follow us" />
          </FooterTitle>

          <SocialLinks />
        </Column>
      </FooterColumns>

      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Copyright>
            <FormattedMessage
              id="footer.copyright"
              defaultMessage="Copyright {siteName} © {year}. All right reserved."
              values={{ siteName: siteTitle, year: new Date().getFullYear() }}
            />
          </Copyright>
        </Box>

        <Box>
          <FooterLogo src={heroLogo} alt="" />
        </Box>
      </Flex>
    </Container>
  </StyledFooter>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: '',
}

export default Footer
